<template>
	<div>
		<indexV2 platform="CPS" />
	</div>
</template>

<script>
import indexV2 from '../../../liability/v2/indexV2.vue';
export default {
	components: {
		indexV2,
	},
};
</script>

<style lang="scss" scoped></style>
